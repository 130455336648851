/* eslint-disable max-classes-per-file */
import React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";

import LazyLoad from "react-lazyload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { COLORS, FONT_FAMILIES } from "../utils/theme";

import { useStaticDomain } from "../utils/strings";
import { StyledComponentsBreakpoints } from "./Layout/Responsive";

import Button from "./Forms/Button";

const iconColor = COLORS.white.rgba.replace(", 1)", ", 0.6)");

const PerformerItem = ({ performer, url }) => (
  <PerformerListItem>
    <Button variant="flat" onClick={() => navigate(url)}>
      <DetailContainer>
        <BackgroundImageContainer
          style={{
            background: `
              linear-gradient(
                rgba(25, 25, 25, 0.666) 0%,
                rgba(25, 25, 25, 0.75) 20%,
                rgba(25, 25, 25, 0.8) 50%,
                rgba(25, 25, 25, 0.75) 80%,
                rgba(25, 25, 25, 0.666) 100%
              ),
              url(${useStaticDomain(performer.acf.photo.sizes.medium)})`,
            backgroundSize: "cover",
          }}
        />
        <PerformerName>
          <span
            dangerouslySetInnerHTML={{
              __html: performer.title.rendered,
            }}
          />
        </PerformerName>

        {performer.acf.hometown && (
          <PerformerHometown>
            <span
              dangerouslySetInnerHTML={{
                __html: performer.acf.hometown,
              }}
            />
          </PerformerHometown>
        )}
      </DetailContainer>
      <NotPhone>
      {performer.acf.international === "1" && (
        <InternationalPerformer>
          <FontAwesomeIcon icon={["fas", "globe-americas"]} color={iconColor} />

          <span>International</span>
        </InternationalPerformer>
      )}

      {performer.acf.florida_exclusive === "1" && (
        <FloridaExclusive>
          <FontAwesomeIcon icon={["fas", "id-badge"]} color={iconColor} />
          <span>Exclusive FL Show</span>
        </FloridaExclusive>
      )}
      </NotPhone>
    </Button>
  </PerformerListItem>
);

export default class PerformerList extends React.Component {
  render() {
    const { performers, urlPrefix } = this.props;

    return (
      <PerformerListComponent>
        {performers.map((performerItem) => {
          const performer = performerItem.node;
          const url = `/${urlPrefix}/${performer.slug}`;

          return (
            <LazyLoad key={performer.id} offset={400} height={140}>
              <PerformerItem performer={performer} url={url} />
            </LazyLoad>
          );
        })}
      </PerformerListComponent>
    );
  }
}

const BandcampPerformerItem = ({ performer, url }) => (
  <PerformerListItem>
    <Button variant="flat" onClick={() => window.open(url, "_blank")}>
      <DetailContainer>
        <BackgroundImageContainer
          style={{
            background: `
              linear-gradient(
                rgba(25, 25, 25, 0.666) 0%,
                rgba(25, 25, 25, 0.75) 20%,
                rgba(25, 25, 25, 0.8) 50%,
                rgba(25, 25, 25, 0.75) 80%,
                rgba(25, 25, 25, 0.666) 100%
              ),
              url(${useStaticDomain(performer.acf.photo.sizes.medium)})`,
            backgroundSize: "cover",
          }}
        />
        <PerformerName>
          <span
            dangerouslySetInnerHTML={{
              __html: performer.title.rendered,
            }}
          />
        </PerformerName>

        {performer.acf.hometown && (
          <PerformerHometown>
            <span
              dangerouslySetInnerHTML={{
                __html: performer.acf.hometown,
              }}
            />
          </PerformerHometown>
        )}
      </DetailContainer>
      {performer.acf.international === "1" && (
        <InternationalPerformer>
          <FontAwesomeIcon icon={["fas", "globe-americas"]} color={iconColor} />

          <span>International</span>
        </InternationalPerformer>
      )}

      {performer.acf.florida_exclusive === "1" && (
        <FloridaExclusive>
          <FontAwesomeIcon icon={["fas", "id-badge"]} color={iconColor} />
          <span>Exclusive FL Show</span>
        </FloridaExclusive>
      )}
    </Button>
  </PerformerListItem>
);
export class BandcampPerformerList extends React.Component {
  render() {
    const { performers } = this.props;

    return (
      <PerformerListComponent>
        {performers.map((performerItem) => {
          const performer = performerItem.node;

          return (
            <LazyLoad key={performer.id} offset={400} height={140}>
              <BandcampPerformerItem
                performer={performer}
                url={performer.acf.link_bandcamp.url}
              />
            </LazyLoad>
          );
        })}
      </PerformerListComponent>
    );
  }
}

const NotPhone = styled.div`
  ${StyledComponentsBreakpoints.Phone`
    display:none;
  `};

  ${StyledComponentsBreakpoints.NotPhone`
    display:inherit;
  `};
`;
const InternationalPerformer = styled.div`
  position: absolute;

  left: 12px;
  bottom: 8px;

  display: flex;
  align-items: center;
  align-content: center;
  color: ${iconColor};

  ${StyledComponentsBreakpoints.Phone`
    left: 10px;
    bottom: 5px;
  `};

  span {
    display: inline-block;
    margin-left: 6px;

    ${FONT_FAMILIES.copy};
    font-weight: 400;

    color: ${COLORS.white.rgba};
    font-size: 0.75rem;

    ${StyledComponentsBreakpoints.Phone`
      font-size: 0.7rem;
    `};
  }
`;
const FloridaExclusive = styled.div`
  position: absolute;
  right: 12px;
  bottom: 8px;

  display: flex;
  align-items: center;
  align-content: center;
  color: ${iconColor};

  ${StyledComponentsBreakpoints.Phone`
    right: 10px;
    bottom: 5px;
  `};

  span {
    display: inline-block;
    margin-left: 6px;

    ${FONT_FAMILIES.copy};
    font-weight: 400;

    color: ${COLORS.white.rgba};
    font-size: 0.75rem;
    ${StyledComponentsBreakpoints.Phone`
      font-size: 0.7rem;
    `};
  }
`;

const PerformerListComponent = styled.ul`
  list-style-type: none;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: flex-start;
  margin-bottom: 4rem;
`;

const PerformerListItem = styled.li`
  z-index: 1;
  margin: 1px 1px;
  width: 33%;
  max-width: 400px;
  height: 150px;
  padding: 0 !important;
  margin: 0 !important;

  @media (max-width: 768px) {
    width: 50%;
  }
  @media (max-width: 500px) {
    width: 100%;
  }

  > button {
    width: 100%;
    height: 100%;
  }
`;

const BackgroundImageContainer = styled.div`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const DetailContainer = styled.div`
  z-index: 1;
`;

const PerformerName = styled.div`
  display: block;

  padding: 2px 4px;
  margin-bottom: 0;
  padding-bottom: 0;

  span {
    ${FONT_FAMILIES.headline};

    font-size: 22px;
    line-height: 24px;
    font-weight: 800;
    color: ${COLORS.lightgrey.rgba};

    ${StyledComponentsBreakpoints.Phone`
      font-size: 22px;
    `};
  }
`;
const PerformerHometown = styled.div`
  display: block;
  padding-top: 0;

  line-height: 16px;

  ${StyledComponentsBreakpoints.Phone`  
    margin-top: -0.25rem;
  `};
  span {
    ${FONT_FAMILIES.copy};
    font-weight: 400;

    color: ${COLORS.white.rgba.replace(", 1)", ", 0.8)")};
    font-size: 0.8rem;

    ${StyledComponentsBreakpoints.Phone`
      font-size: 0.7rem;
      line-height: 0.8rem;

    `};
  }
`;
